<template>
  <div>
    <div style="height: 0;overflow: hidden">
      <save-resource-btn ref="saveResourceBtn" @getList="$emit('onGet')"/>
      <delete-resource-btn ref="deleteResourceBtn" @getList="$emit('onGet')"/>
    </div>
    <span style="margin-right: 20px">
      <span v-if="data.type === 'dir'">
        <i class="el-icon-folder-opened"/>
        <span>&nbsp;</span>
        <span>{{data.name}}</span>
      </span>
      <span v-if="data.type === 'menu'">
        <i class="el-icon-tickets"/>
        <span>&nbsp;</span>
        <span>{{data.name}}</span>
      </span>
      <span v-if="data.type === 'btn'">
        <el-button class="mini-btn">{{data.name}}</el-button>
      </span>
    </span>
    <el-button type="text" size="mini" @click.stop="() => append(data)">{{$t('button.add')}}</el-button>
    <el-button type="text" size="mini" @click.stop="() => edit(data)">{{$t('button.edit')}}</el-button>
    <el-button
      type="text"
      size="mini"
      style="color: red"
      @click.stop="() => remove(data)">
      {{$t('button.delete')}}
    </el-button>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span>{{data.mark}}</span>
    <span>&nbsp;</span>
    <el-button type="text" icon="el-icon-document-copy" @click.stop="copy(data.mark)"/>
  </div>
</template>
<script>
import SaveResourceBtn from "@/views/system/resource/btns/saveResourceBtn";
import DeleteResourceBtn from "@/views/system/resource/btns/deleteResourceBtn";
import {copy} from "@/utils/func";

export default {
  name: "EachResource",
  components: {DeleteResourceBtn, SaveResourceBtn},
  props: ['node', 'data'],
  data() {
    return {
      copy
    }
  },
  methods: {
    append(data) {
      this.$refs.saveResourceBtn.start(0, data.id)
    },
    edit(data) {
      this.$refs.saveResourceBtn.start(data.id, data.pid)
    },
    remove(data) {
      this.$confirm(this.$t('confirm.deleteBatch'), this.$t('message.title'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$refs.deleteResourceBtn.start([data.id])
      }).catch(() => {
      
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.mini-btn {
  padding: 3px;
}
</style>